<script setup lang="ts">
  import type { IChatMessage } from '~~/types/chat'
  import { ChatMessageStatus } from '~~/types/chat'

  const props = defineProps<{
    chatMessage: IChatMessage
    pending?: boolean
    reply?: boolean
  }>()

  const { locale } = useI18n()

  const { displayName: creatorName } = useChatCreator(
    props.chatMessage.createdBy?.account || props.chatMessage.createdBy?.user,
  )
  const createdAt = toRef(() => props.chatMessage.createdAt)
  const createdAtFull = computed<string | undefined>(() =>
    dateFormatted(createdAt.value),
  )
  const createdAtRelative = computed<string | undefined>(() =>
    dateToRelative(createdAt.value, locale.value),
  )
  const isDateRelative = ref<boolean>(true)
  const isUnread = ref<boolean>(
    props.chatMessage.status === ChatMessageStatus.SENT,
  )

  onMounted(() => {
    setTimeout(() => (isUnread.value = false), 1000)
  })
</script>

<template>
  <div class="group/chat-message flex flex-col items-start gap-2">
    <div
      class="hidden size-8 shrink-0 rounded-full bg-dark-100 dark:bg-dark-400"
    />

    <div
      class="max-w-[80%]"
      :class="{
        'ml-auto': reply,
      }"
    >
      <div
        class="mb-1 flex items-center gap-1 leading-none"
        :class="{
          'justify-end': reply,
        }"
      >
        <div
          class="text-sm font-semibold"
          :class="{
            'order-2 text-primary-500 dark:text-primary-400': reply,
          }"
        >
          <span>{{ creatorName }}</span>
        </div>

        <div
          class="ml-3 text-xs text-dark-500 opacity-50 transition-opacity group-hover/chat-message:opacity-100 dark:text-dark-400"
        >
          <span
            class="cursor-pointer text-xs transition-colors hover:text-primary-500"
            @click="isDateRelative = !isDateRelative"
          >
            <span>{{
              isDateRelative ? createdAtRelative : createdAtFull
            }}</span>
          </span>
        </div>
      </div>

      <div
        class="relative rounded-b-lg px-3 py-2 leading-normal"
        :class="{
          'rounded-tr-lg bg-dark-25 dark:bg-dark-700/90': !reply,
          'ml-auto rounded-tl-lg bg-dark-50 dark:bg-dark-600/70': reply,
        }"
      >
        <Transition
          name="fade"
          mode="out-in"
        >
          <div
            v-if="isUnread"
            class="absolute top-1 size-2 rounded-full bg-info-500"
            :class="{
              'right-1': !reply,
              'left-1': reply,
            }"
          />
        </Transition>

        <p
          v-if="!pending"
          class="m-0 whitespace-pre-line"
        >
          {{ chatMessage.content }}
        </p>

        <div
          v-else
          class="py-1"
        >
          <ChatTypingDots
            class="h-[3px] justify-end text-info-500 dark:text-info-300"
          />
        </div>
      </div>
    </div>
  </div>
</template>
