import type { IAccount } from '~~/types/account'
import type { PrismaHub } from '~~/types/db'
import type { IUser } from '~~/types/user'
import type { IRecord } from './record'

export const CHAT_SUPPORT_CHANNEL_PREFIX = 'private-chat-support'
export const CHAT_SUPPORT_CHANNEL_PRESENCE_PREFIX = 'presence-chat-support'

export enum ChatSupportEvent {
  MESSAGE = 'chat-support-message',
}

export enum ChatStatus {
  ACTIVE = 'active',
}

export enum ChatMessageStatus {
  SENT = 'sent',
  READ = 'read',
}

export interface IChatSender {
  account?: IAccount
  user?: IUser
}

export interface IChatPresence<T extends IRecord | undefined = undefined> {
  id: string
  info?: T
}

export interface IChatMessage {
  id: string
  chatId: string
  type: string
  content: string
  status: string
  createdAt: string
  createdById: string
  createdBy?: IChatSender
}

export interface IChat {
  id: string
  name: string
  status: string
  priority: number
  createdAt: string
  createdById: string
  createdBy: IChatSender
  messagesUnread?: number
  messages?: IChatMessage[]
}

export enum ChatReplySuggestionStatus {
  ACTIVE = 'active',
  DISABLED = 'disabled',
}

export type TChatReplySuggestion =
  PrismaHub.ChatReplySuggestionGetPayload<undefined>

export interface IChatStats {
  chatId: string
  unreadCount: number
}
