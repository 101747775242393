<template>
  <div class="flex items-center gap-0.5">
    <div
      v-for="n in 3"
      :key="n"
      class="animate-typing-mercury inline-block aspect-[1/1] h-full rounded-full bg-current align-middle transition-all"
      :style="{
        animationDelay: n * 2 * 100 + 'ms',
      }"
    />
  </div>
</template>

<style lang="scss" scoped>
  .typing {
    @apply flex h-6 items-center gap-0.5;

    .dot {
      @apply inline-block size-[3px] rounded-full bg-info-500 align-middle;
    }
  }

  .animate-typing-mercury {
    animation: mercury-typing 1.8s infinite ease-in-out;
  }

  @keyframes mercury-typing {
    0% {
      transform: translateY(0);
      opacity: 0.9;
    }

    28% {
      transform: translateY(-3px);
      opacity: 0.6;
    }

    44% {
      transform: translateY(0);
      opacity: 0.7;
    }

    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  .animate-typing-fade {
    animation: typing-fade 1.4s infinite;
    animation-timing-function: cubic-bezier(0.5, 0.5, 1);
  }

  @keyframes typing-fade {
    0% {
      opacity: 0.2;
      transform: scale(0.8, 0.8);
    }

    5% {
      opacity: 0.25;
    }

    50% {
      transform: scale(1, 1);
    }

    55% {
      opacity: 1;
    }

    100% {
      opacity: 0.2;
      transform: scale(0.8, 0.8);
    }
  }
</style>
