import { consola } from 'consola'
import type { Members, PresenceChannel } from 'pusher-js'
import Pusher from 'pusher-js'
import type { NuxtError } from 'nuxt/app'
import { useAuthStore } from '~~/stores/auth'
import type { IAccount } from '~~/types/account'
import type { IChat, IChatMessage, IChatPresence } from '~~/types/chat'
import {
  ChatMessageStatus,
  ChatSupportEvent,
  CHAT_SUPPORT_CHANNEL_PREFIX,
  CHAT_SUPPORT_CHANNEL_PRESENCE_PREFIX,
} from '~~/types/chat'
import type { IRecordResponse, IRecordResponseList } from '~~/types/record'
import type { IUser } from '~~/types/user'
import { WINDOW_BREAKPOINT_MOBILE } from '~~/types/window'

interface IPusherError extends Record<string, unknown> {
  error: {
    data: {
      code: number
    }
  }
}

export const useChatCreator = (
  data:
    | MaybeRef<IAccount | IUser | undefined>
    | ComputedRef<IAccount | IUser | undefined>
    | undefined,
) => {
  const creator = toRef(data)

  const displayName = computed<string>(
    () =>
      (creator.value?.firstName &&
        creator.value.firstName +
          (creator.value.lastName ? ' ' + creator.value.lastName : '')) ||
      creator.value?.email ||
      'Unknown',
  )

  return {
    displayName,
  }
}

export const useChat = (
  chatItem: MaybeRef<IChat> | ComputedRef<IChat>,
  endpoint: string,
) => {
  const chat = toRef(chatItem)
  const initializing = ref<boolean>(true)
  const pending = ref<boolean>(false)
  const isReplySubmit = ref<boolean>(false)
  const { account } = storeToRefs(useAuthStore())

  const messagesLists = ref<IRecordResponseList<IChatMessage>[]>([])
  const messages = ref<IChatMessage[]>([])
  const messagesContainer = ref<HTMLElement | null>(null)
  const messagesScrollToBottom = async (): Promise<void> => {
    await nextTick()

    if (messagesContainer.value) {
      messagesContainer.value.scrollTop = messagesContainer.value.scrollHeight
    }
  }
  const sendMessage = async (message: string): Promise<void> => {
    try {
      if (!account.value || !chat.value.id || !message) {
        return
      }

      isReplySubmit.value = true

      messages.value.push({
        id: '',
        chatId: chat.value.id,
        type: 'user',
        status: ChatMessageStatus.SENT,
        content: message,
        createdById: account.value.id,
        createdBy: (account.value && { account: account.value }) || undefined,
        createdAt: new Date().toISOString(),
      })
      messagesScrollToBottom()

      const messageResult = await $request<IRecordResponse<IChatMessage>>(
        `/api/${endpoint}/${chat.value.id}/messages`,
        {
          method: 'POST',
          body: {
            message,
          },
        },
      )

      messages.value.pop()
      messages.value.push(messageResult.item)
      messagesScrollToBottom()
    } catch (e) {
      createError(e as string | Partial<NuxtError>)
    } finally {
      isReplySubmit.value = false
    }
  }

  const init = async (): Promise<void> => {
    try {
      initializing.value = true

      if (!chat.value.id) {
        return
      }

      messages.value = []

      const messagesResult = await $request<IRecordResponseList<IChatMessage>>(
        `/api/${endpoint}/${chat.value.id}/messages/list`,
        {
          query: {
            markAsRead: true,
          },
        },
      )

      messagesLists.value.push(messagesResult)
      messages.value = messagesResult.items.sort(
        (a, b) =>
          new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
      )
      messagesScrollToBottom()
    } catch (e) {
      // createError(e as string | Partial<NuxtError>)
      useLogger().debug(e)
    } finally {
      initializing.value = false
    }
  }

  const priorityToggle = async (): Promise<void> => {
    const priorityCurrent = chat.value.priority

    try {
      const priority = priorityCurrent ? 0 : 1
      chat.value.priority = priority

      await $request<string>(`/api/${endpoint}/${chat.value.id}`, {
        method: 'PATCH',
        body: {
          priority,
        },
      })
    } catch (e) {
      chat.value.priority = priorityCurrent
      createError(e as NuxtError)
    }
  }

  onMounted(() => init())

  return {
    chat,
    initializing,
    pending,
    isReplySubmit,
    messages,
    messagesContainer,
    messagesScrollToBottom,
    sendMessage,
    priorityToggle,
  }
}

export const useChatSocket = (
  chatItem: MaybeRef<IChat> | ComputedRef<IChat>,
  endpoint: string,
) => {
  const config = useRuntimeConfig()
  const { account } = storeToRefs(useAuthStore())

  if (!config.public.pusher.key || !config.public.pusher.cluster) {
    throw createError({
      statusCode: 500,
      statusMessage: 'Pusher setup error.',
    })
  }

  const {
    chat,
    initializing,
    pending,
    isReplySubmit,
    messages,
    messagesContainer,
    messagesScrollToBottom,
    sendMessage,
    priorityToggle,
  } = useChat(chatItem, endpoint)
  const online = ref<string[]>([])

  const channelName = computed<string>(
    () => `${CHAT_SUPPORT_CHANNEL_PREFIX}-${chat.value.id}`,
  )
  const channelPresenceName = computed<string>(
    () => `${CHAT_SUPPORT_CHANNEL_PRESENCE_PREFIX}-${chat.value.id}`,
  )

  Pusher.logToConsole = !!import.meta.dev
  const pusher = new Pusher(config.public.pusher.key, {
    cluster: config.public.pusher.cluster,
    forceTLS: true,
    channelAuthorization: {
      endpoint: `/api/${endpoint}/${chat.value.id}/pusher/auth`,
      transport: 'ajax',
      params: {
        id: account.value?.id,
      },
    },
  })

  const channelInit = (): void => {
    const channel = pusher.subscribe(channelName.value)
    useDebug(() =>
      channel.bind('pusher:subscription_succeeded', () => {
        useLogger().log('Successfully subscribed', channelName.value)
      }),
    )
    channel.bind(ChatSupportEvent.MESSAGE, (data: IChatMessage) => {
      if (data.createdById !== account.value?.id) {
        messages.value.push(data)
        messagesScrollToBottom()
      }
    })

    const channelPresence = pusher.subscribe(
      channelPresenceName.value,
    ) as PresenceChannel
    channelPresence.bind(
      'pusher:subscription_succeeded',
      (members: Members) => {
        const membersIds: string[] = []
        members.each((member: IChatPresence) => {
          membersIds.push(member.id)
        })
        online.value = membersIds
      },
    )
    channelPresence.bind('pusher:member_added', (member: IChatPresence) => {
      if (!online.value.includes(member.id)) {
        online.value.push(member.id)
      }
    })
    channelPresence.bind('pusher:member_removed', (member: IChatPresence) => {
      const index = online.value.indexOf(member.id)

      if (index !== -1) {
        online.value.splice(index, 1)
      }
    })

    pusher.connection.bind('error', (e: IPusherError) => {
      useLogger().debug('Pusher connection error', e)

      if (e.error.data.code === 4004) {
        consola.error('Pusher connection error', 'Cause: limit')
      }
    })
  }

  onMounted(() => {
    channelInit()
  })
  onUnmounted(async () => {
    pusher.unsubscribe(channelName.value)
    pusher.unsubscribe(channelPresenceName.value)
  })

  return {
    chat,
    initializing,
    pending,
    online,
    isReplySubmit,
    messages,
    messagesContainer,
    messagesScrollToBottom,
    sendMessage,
    priorityToggle,
  }
}

export const useChatList = async (endpoint: string) => {
  const chatListFilters = ref<{
    search: string
  }>({
    search: '',
  })

  const isChatListOpened = ref<boolean>(true)
  const chatCurrent = ref<IChat | undefined>()
  const { width: windowWidth } = useWindowSize()
  const chatCurrentToggle = async (chat: IChat): Promise<void> => {
    if (chat.id !== chatCurrent.value?.id) {
      chatCurrent.value = undefined
      await nextTick()
      chatCurrent.value = chat

      // When selecting certain chat it is marked as read during its messages
      // request, so set the unread messages count to 0.
      if (chatCurrent.value?.messagesUnread) {
        chatCurrent.value.messagesUnread = 0
      }

      if (windowWidth.value <= WINDOW_BREAKPOINT_MOBILE) {
        isChatListOpened.value = false
      }
    } else {
      chatCurrent.value = undefined
    }
  }

  const {
    items: chatListItems,
    container: chatListContainer,
    initializing,
    pending,
    reset,
  } = await useRecordListInfinite<IChat>(
    endpoint,
    { limit: 20 },
    chatListFilters,
  )

  const chatsFiltered = computed<IChat[]>(() =>
    [...chatListItems.value].sort(
      (a, b) =>
        b.priority - a.priority ||
        (b.messagesUnread ? 1 : 0) - (a.messagesUnread ? 1 : 0),
    ),
  )
  const refreshList = async (): Promise<void> => {
    try {
      chatCurrent.value = undefined
      await reset()
    } catch (e) {
      createError(e as NuxtError)
    }
  }

  watch(
    chatListFilters,
    () => {
      chatCurrent.value = undefined
    },
    { deep: true },
  )

  return {
    chatListContainer,
    chatListFilters,
    isChatListOpened,
    chatsFiltered,
    initializing,
    pending,
    refreshList,

    chatCurrent,
    chatCurrentToggle,
  }
}
