<script setup lang="ts">
  import { useMagicKeys, useFocus } from '@vueuse/core'

  const props = defineProps<{
    disabled?: boolean
    submitLabel?: string
    suggestion?: string
  }>()

  const emit = defineEmits<{
    (e: 'submit', message: string): void
  }>()

  const slots = useSlots()

  const message = ref<string>(props.suggestion || '')
  const messageInput = ref<HTMLTextAreaElement | null>(null)

  const { ctrl, enter } = useMagicKeys()
  const { focused: isMessageInputFocused } = useFocus(messageInput)

  const messageValueChange = async (): Promise<void> => {
    if (messageInput.value) {
      await nextTick()

      messageInput.value.style.height = ''
      messageInput.value.style.height =
        Math.min(messageInput.value.scrollHeight, 240) + 'px'
    }
  }
  const submit = (): void => {
    if (message.value) {
      emit('submit', message.value.trim())
    }

    message.value = ''
    messageInput.value?.focus()
  }

  watch(message, () => messageValueChange(), { immediate: true })
  watchEffect(() => {
    if (
      ctrl?.value &&
      enter?.value &&
      isMessageInputFocused.value &&
      !!message.value
    ) {
      submit()
    }
  })

  onMounted(() => messageInput.value?.focus())
</script>

<template>
  <div
    class="flex h-auto flex-col rounded-md bg-white py-1 pl-3 pr-1 dark:bg-dark-700"
  >
    <form
      autocomplete="off"
      class="flex grow flex-col"
      @submit.prevent="submit()"
    >
      <fieldset
        class="flex grow flex-col"
        :disabled="disabled"
      >
        <div class="mt-auto flex items-center gap-4">
          <textarea
            id="chat-form-message"
            ref="messageInput"
            v-model="message"
            :disabled="disabled"
            rows="1"
            :placeholder="$t('chat.label.enter-message')"
            class="w-full resize-none rounded border-0 bg-transparent p-0 shadow-none outline-none ring-0 placeholder:text-sm placeholder:leading-6 placeholder:text-dark-400 focus:ring-transparent disabled:placeholder:text-dark-300"
          />

          <div class="mt-auto flex">
            <button
              type="submit"
              :disabled="disabled || !message"
              class="relative bg-primary-500 p-2 text-white transition-all after:absolute after:right-0 after:top-1/2 after:h-2/3 after:w-px after:-translate-y-1/2 after:bg-white/40 after:content-[''] hover:bg-primary-400 disabled:opacity-60 disabled:hover:bg-primary-500"
              :class="{
                'rounded-md': !slots.buttons,
                'rounded-s-md': slots.buttons,
              }"
              :title="$t('chat.label.send')"
            >
              <SvgImage
                symbol="icon-paper-airplane-solid"
                class="size-5"
              />
            </button>

            <slot name="buttons" />
          </div>
        </div>
      </fieldset>
    </form>
  </div>
</template>
